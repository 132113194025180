import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { SectionHeading } from "~/atoms/IndexPageSectionHeading";
import { Container } from "~/atoms/Container";
import { Feature } from "~/atoms/IndexPageFeature";
import { breakPoints } from "~/utils/variables";

export const Features: React.FC<{}> = () => {
  const { feature1, feature2, feature3, feature4 } = useStaticQuery<GatsbyTypes.featureImageQuery>(graphql`
    query featureImage {
      feature1: file(relativePath: { eq: "feature1.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600, quality: 100, placeholder: NONE, layout: CONSTRAINED)
        }
      }
      feature2: file(relativePath: { eq: "feature2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600, quality: 100, placeholder: NONE, layout: CONSTRAINED)
        }
      }
      feature3: file(relativePath: { eq: "feature3.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600, quality: 100, placeholder: NONE, layout: CONSTRAINED)
        }
      }
      feature4: file(relativePath: { eq: "feature4.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600, quality: 100, placeholder: NONE, layout: CONSTRAINED)
        }
      }
    }
  `);

  return (
    <>
      <style jsx>{`
        .whyChooseUsSession {
          content: "";
          display: block;
          height: 80px;
          margin-top: -80px;
          visibility: hidden;
        }
        .wrapper {
          padding: 70px 30px;
        }
        .wrapper :global(.container) {
          flex-direction: column;
        }
        @media (${breakPoints.sp}) {
          .wrapper {
            padding: 50px 0 0;
          }
          .wrapper :global(.container) {
            padding: 0;
          }
        }
        @media (${breakPoints.pc}) {
          .features :global(.wrapper:first-child) {
            margin: 50px 0 0;
          }
          .features :global(.wrapper:last-child) {
            margin: 0;
          }
        }
      `}</style>
      <div id="whyChooseUs" className="whyChooseUsSession" />
      <div className="wrapper">
        <Container>
          <SectionHeading
            main={
              <>
                <span>
                  <span className="metricResize">Refcome</span>が選ばれる理由
                </span>
              </>
            }
            sub={
              <>
                <span>Refcomeは企業の採用を</span>
                <span>成功に導く伴走者です</span>
              </>
            }
          />
          <div className="features">
            <Feature
              number="01"
              subTitle="大手からベンチャーまで850社以上の"
              title="採用支援実績"
              description="大企業やベンチャー企業、省庁など、幅広い支援実績を誇ります。多様な企業への支援で培ったノウハウを活かし、採用課題の解決に最適なリファラル採用の仕組みを構築します。"
              imageData={feature1?.childImageSharp?.gatsbyImageData}
            />
            <Feature
              number="02"
              subTitle="専属プロフェッショナルによる月20時間超えの"
              title="徹底サポート"
              description="月間20～50時間をかけて専属の担当者が深く関与し、施策を企画・実行します。さらに、導入から社内定着までを見据え、社員向け説明会や社内広報記事・ポスターの作成など、オンボーディングを徹底的にサポートします。"
              imageData={feature2?.childImageSharp?.gatsbyImageData}
              direction="ltr"
            />
            <Feature
              number="03"
              subTitle="成果報酬型を含む"
              title="柔軟な料金体系"
              description="初期費用0円で利用可能な成果報酬型サービスや、予算管理がしやすく成果を安定して出せる固定費型サービスなど、課題や状況に応じた柔軟な料金プランをご提供します。最適なプラン、導入時のハードルやリスクを抑え、安心して採用活動を進めることが可能です。"
              imageData={feature3?.childImageSharp?.gatsbyImageData}
            />
            <Feature
              number="04"
              subTitle="リファラル採用に特化"
              title="専用ツールのご提供"
              description="独自のタレントプール機能を持った、リファラル採用を活性化するクラウドサービスが利用できます。人事・社員・友人の連携効率化により、欲しい人材との早期マッチングを実現します。"
              imageData={feature4?.childImageSharp?.gatsbyImageData}
              direction="ltr"
            />
          </div>
        </Container>
      </div>
    </>
  );
};
