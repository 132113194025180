import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "gatsby";
import SiteMainVisualSBaC from "~/images/SiteMainVisualSB&C.png";
import SiteMainVisualOMRON from "~/images/SiteMainVisualOMRON.png";
import siteMainVisualFindy from "~/images/SiteMainVisualFindy.png";

const containerStyle = {
  position: "relative" as const,
  width: "100%",
  paddingBottom: "30px",
};

const paginationStyle = {
  position: "absolute" as const,
  bottom: 0,
  left: 0,
  width: "100%",
  textAlign: "center" as const,
  zIndex: 10,
};

export const MainVisualSwiper = () => {
  return (
    <div style={containerStyle}>
      <Swiper
        pagination={{
          clickable: true,
          el: ".swiper-pagination-custom",
        }}
        modules={[Autoplay, Pagination]}
        loop={true}
        speed={800}
        slidesPerView={1}
        direction="horizontal"
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        style={{ width: "100%" }}
      >
        <SwiperSlide key="slide-1">
          <Link to={`/cases/4oDH3961iSvtSbWCVxOnBT`}>
            <img src={SiteMainVisualSBaC} style={{ width: "100%" }} alt="SB&C" />
          </Link>
        </SwiperSlide>
        <SwiperSlide key="slide-2">
          <Link to={`/cases/5eSQdSCQulCzyMNSYgPoIT`}>
            <img src={SiteMainVisualOMRON} style={{ width: "100%" }} alt="OMRON" />
          </Link>
        </SwiperSlide>
        <SwiperSlide key="slide-3">
          <Link to={`/cases/6WkJwT5oM2DNAdWxVJiTL4`}>
            <img src={siteMainVisualFindy} style={{ width: "100%" }} alt="Findy" />
          </Link>
        </SwiperSlide>
      </Swiper>
      <div className="swiper-pagination-custom" style={paginationStyle}></div>
    </div>
  );
};
