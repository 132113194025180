import React, { ReactNode } from "react";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";

interface Props {
  main: ReactNode;
  sub: ReactNode;
}

export const SectionHeading: React.FC<Props> = ({ main, sub }) => {
  return (
    <>
      <style jsx>{`
        .wrapper {
          margin: 0 0 40px;
          position: relative;
          text-align: center;
          width: 100%;
        }

        .mainHeading {
          font-size: 40px;
          margin: 0;
          font-weight: 500;
        }

        .subHeading {
          color: ${colors.lightText};
          font-size: 20px;
          line-height: 1.6;
        }

        .wrapper :global(.metricResize) {
          font-size: 48px;
        }

        @media (${breakPoints.sp}) {
          .wrapper {
            margin: 0 0 30px;
            padding: 0 30px 20px;
          }

          .wrapper:after {
            height: 6px;
            width: 40px;
          }

          .mainHeading {
            font-size: 22px;
            line-height: 1.4;
            margin: 0 0 15px;
          }

          .subHeading {
            font-size: 16px;
          }

          .wrapper :global(.metricResize) {
            font-size: 25px;
          }
        }
      `}</style>
      <div className="wrapper">
        <h2 className="mainHeading">{main}</h2>
        <p className="subHeading">{sub}</p>
      </div>
    </>
  );
};
