import React from "react";
import { CallToActionButton } from "~/atoms/CallToActionButton";
import { SectionHeading } from "~/atoms/IndexPageSectionHeading";
import { PATH } from "~/constant/path";
import { colors } from "~/utils/colors";
import { breakPoints, siteWidth } from "~/utils/variables";
import { IndexCaseCard } from "~/molecules/IndexCaseCard";
import { IoMdArrowForward } from "@react-icons/all-files/io/IoMdArrowForward";
import CaseTopImage from "~/images/caseTopImage.png";

type Props = {
  caseCards: GatsbyTypes.ContentfulCase[];
};

export const CasesOnTop: React.FC<Props> = ({ caseCards }) => {
  return (
    <>
      <div id="cases" className="casesSession" />
      <div className="container">
        <div className="titleWrapper">
          <img src={CaseTopImage} className="caseTopImage" />
          <div className="titleCover" />
          <div className="title">
            <SectionHeading
              main={<span>導入事例</span>}
              sub={
                <>
                  <span>多くの企業がリファラル採用の</span>
                  <span>定着に成功しています</span>
                </>
              }
            />
          </div>
        </div>
        <div className="casesWrapper">
          <div className="cases">
            {caseCards.map((caseCard, index) => (
              <div key={index} className="card">
                <IndexCaseCard caseCard={caseCard} />
              </div>
            ))}
          </div>
        </div>
        <div className="buttonWrapper">
          <CallToActionButton to={PATH.CASES.to} size={`100%`}>
            <div className="buttonText">
              <span>一覧をみる</span>
              <IoMdArrowForward style={{ fontSize: 24 }} />
            </div>
          </CallToActionButton>
        </div>
      </div>

      <style jsx>{`
        .casesSession {
          content: "";
          display: block;
          height: 80px;
          margin-top: -80px;
          visibility: hidden;
        }
        .container {
          padding-bottom: 48px;
        }
        .titleWrapper {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          height: 300px;
          width: 100%;
          overflow: hidden;
          position: relative;
          background-color: #f2f9fd;
        }
        .titleCover {
          position: absolute;
          top: 0;
          left: 0;
          background: linear-gradient(to right, #f2f9fd, ${colors.white});
          height: 100%;
          width: 100%;
          opacity: 0.6;
        }
        .title {
          position: absolute;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .casesWrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding: 0 20px;
          margin-top: 48px;
        }
        .cases {
          display: flex;
          gap: 24px;
          max-width: ${siteWidth.default};
        }
        .buttonWrapper {
          display: flex;
          justify-content: center;
          width: 100%;
          margin-top: 48px;
          padding: 0 20px;
        }
        .buttonText {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 18px 0 26px;
          gap: 8px;
        }
        @media (${breakPoints.sp}) {
          .cases {
            flex-direction: column;
          }
          .titleWrapper {
            height: 200px;
            width: 100%;
            background-color: ${colors.white};
            padding-top: 40px;
          }
          .titleCover {
            display: none;
          }
          .caseTopImage {
            display: none;
          }
          .casesWrapper {
            margin-top: 0;
          }
        }
      `}</style>
    </>
  );
};
