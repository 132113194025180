import React from "react";
import { graphql } from "gatsby";
import { Layout } from "~/templates/Layout";
import { Hero } from "../molecules/IndexPageHero";
import { Services } from "~/molecules/IndexPageServices";
import { Features } from "~/molecules/IndexPageFeatures";
import { CasesOnTop } from "~/molecules/IndexPageCases";
import { AskUs } from "~/molecules/IndexPageAskUs";
import { CompanyLogos } from "~/molecules/CompanyLogos";
import { DocumentDownloadGuid } from "~/molecules/IndexPageDocumentDownloadGuide";
import { SupportStep } from "~/molecules/IndexPageSupportStep";
import { Questions } from "~/molecules/IndexPageQuestions";
import { InPageLinks } from "~/molecules/IndexPageInPageLinks";
import { Banner } from "~/molecules/IndexPageBanner";

type Props = {
  data: {
    caseCaS: GatsbyTypes.ContentfulCase;
    caseOmron: GatsbyTypes.ContentfulCase;
    caseFindy: GatsbyTypes.ContentfulCase;
  };
};

const IndexPage: React.FC<Props> = ({ data }) => {
  const { caseCaS, caseOmron, caseFindy } = data;

  return (
    <>
      <Banner />
      <Layout isTopPage>
        <Hero />
        <InPageLinks />
        <CompanyLogos />
        <Services />
        <Features />
        <DocumentDownloadGuid />
        <CasesOnTop caseCards={[caseCaS, caseOmron, caseFindy]} />
        <SupportStep />
        <Questions />
        <AskUs />
      </Layout>
    </>
  );
};

export default IndexPage;

export const IndexPageCaseQuery = graphql`
  query IndexPageCase {
    caseCaS: contentfulCase(contentful_id: { eq: "4oDH3961iSvtSbWCVxOnBT" }) {
      id
      contentful_id
      title
      company {
        id
        name
      }
      ogpImage {
        gatsbyImageData(aspectRatio: 1.618, width: 600)
      }
    }
    caseOmron: contentfulCase(contentful_id: { eq: "5eSQdSCQulCzyMNSYgPoIT" }) {
      id
      contentful_id
      title
      company {
        id
        name
      }
      ogpImage {
        gatsbyImageData(aspectRatio: 1.618, width: 600)
      }
    }
    caseFindy: contentfulCase(contentful_id: { eq: "6WkJwT5oM2DNAdWxVJiTL4" }) {
      id
      contentful_id
      title
      company {
        id
        name
      }
      ogpImage {
        gatsbyImageData(aspectRatio: 1.618, width: 600)
      }
    }
  }
`;
