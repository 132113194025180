import React from "react";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";
import { MainVisualSwiper } from "~/organisms/MainVisualSwiper";
import { DocumentDownloadButton } from "~/organisms/DocumentDownloadButton";
import { PATH } from "~/constant/path";

export const Hero: React.FC = () => {
  return (
    <>
      <div id="hero" className="hero">
        <div className="container">
          <div className="leftContainer">
            <h1 className="title">
              リファラル採用を
              <br />
              成功させるパートナー
            </h1>
            <p className="description">リファラル採用の立ち上げ支援RPOサービス</p>
            <div className="documentDownloadButtonWrapper">
              <DocumentDownloadButton to={PATH.INQUIRY_CONTACT.to} />
            </div>
          </div>
          <div className="rightContainer">
            <MainVisualSwiper />
          </div>
        </div>
      </div>
      <style jsx>{`
        .hero {
          margin-top: 92px;
          padding: 50px 30px;
        }
        .container {
          display: flex;
          margin: 0 auto;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          max-width: 1200px;
        }
        .leftContainer {
          width: 100%;
        }
        .rightContainer {
          margin-left: auto;
          width: 100%;
          max-width: 566px;
        }
        .title {
          font-size: 53px;
          line-height: 1.5;
          letter-spacing: 1px;
        }
        .description {
          color: ${colors.lightText};
          margin-top: 10px;
          font-size: 22px;
          letter-spacing: 2px;
        }
        .documentDownloadButtonWrapper {
          margin-top: 32px;
        }
        @media (min-width: 1000px) and (max-width: 1200px) {
          .container {
            display: flex;
            margin: 0 auto;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            max-width: 1000px;
          }
          .leftContainer {
            width: 100%;
          }
          .rightContainer {
            margin-left: auto;
            width: 100%;
            max-width: 460px;
          }
          .title {
            font-size: 48px;
            line-height: 1.5;
          }
          .description {
            color: ${colors.lightText};
            margin-top: 16px;
            font-size: 18px;
          }
        }
        .hero {
          margin-top: 112px;
        }
        @media (max-width: 1135px) {
          .hero {
            margin-top: 72px;
          }
          .container {
            margin: 0 9rem;
            gap: 1rem;
          }

          .title {
            font-size: 32px;
            line-height: 1.5;
          }
          .description {
            color: ${colors.lightText};
            margin-top: 16px;
            font-size: 16px;
          }
        }
        @media (${breakPoints.tablet}) {
          .container {
            display: flex;
            margin: 0 10rem;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            max-width: 1000px;
          }
          .leftContainer {
            flex: 1;
          }
          .rightContainer {
            flex: 1;
            width: 100%;
            max-width: 420px;
            margin-left: 0;
          }
          .title {
            font-size: 32px;
            line-height: 1.5;
          }
          .description {
            color: ${colors.lightText};
            margin-top: 16px;
            font-size: 16px;
          }
        }

        @media (${breakPoints.sp}) {
          .hero {
            margin-top: 72px;
            padding: 0 20px;
          }
          .container {
            display: flex;
            margin: 0 auto;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            max-width: 1000px;
          }
          .leftContainer {
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 0;
            text-align: center;
            margin-top: 16px;
          }
          .rightContainer {
            width: 100%;
            max-width: 420px;
            position: static;
            order: -1;
          }
          .title {
            font-size: 28px;
            letter-spacing: 1.8px;
          }
          .description {
            color: ${colors.lightText};
            font-size: 15px;
            margin-top: 8px;
          }
        }
      `}</style>
    </>
  );
};
