import React from "react";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";
import AskUsPng from "~/images/askUs.png";
import { DocumentDownloadButton } from "~/organisms/DocumentDownloadButton";
import { TelephoneNumberPanel } from "~/organisms/TelephoneNumberPanel";
import { PATH } from "~/constant/path";

export const AskUs = () => {
  return (
    <>
      <div className="container">
        <div className="body">
          <div className="titleWrapper">
            <span className="title">Refcomeに相談してみませんか？</span>
          </div>
          <div className="subTextWrapper">
            <span className="subText">リファラル採用に関するご質問、ご相談など気軽にお問い合わせください</span>
          </div>
          <div className="buttonAndPhoneNumber">
            <DocumentDownloadButton to={PATH.INQUIRY_CONTACT.to} />
            <TelephoneNumberPanel />
          </div>
        </div>
      </div>

      <style jsx>{`
        .container {
          display: flex;
          justify-content: center;
          background-image: url(${AskUsPng});
          background-color: ${colors.lightOrange};
          background-position: right;
          background-size: contain;
          height: 320px;
          padding: 40px 20px;
        }
        .body {
          width: 1000px;
        }
        .title {
          font-size: 36px;
          font-weight: bold;
        }
        .subTextWrapper {
          margin-top: 16px;
        }
        .subText {
          font-size: 16px;
          color: ${colors.lightText};
        }
        .buttonAndPhoneNumber {
          display: flex;
          gap: 24px;
          margin-top: 32px;
        }
        @media (${breakPoints.tablet}) {
          .container {
            background-image: none;
            height: auto;
          }
          .body {
            width: 100%;
          }
          .titleWrapper {
            display: flex;
            justify-content: center;
            width: 100%;
          }
          .title {
            font-size: 22px;
            text-align: center;
          }
          .subTextWrapper {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: 6px;
          }
          .subText {
            font-size: 14px;
            text-align: center;
          }
          .buttonAndPhoneNumber {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: 14px;
          }
        }
      `}</style>
    </>
  );
};
