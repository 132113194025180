import React, { FC } from "react";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";

type QuestionsType = {};

export const Questions: FC<QuestionsType> = () => {
  return (
    <>
      <div className="container">
        <div className="titleWrapper">
          <span className="title">よくある質問</span>
        </div>
        <div className="qaWrapper">
          <details className="qa">
            <summary>Q. 料金体系はどのようになっていますか？</summary>
            <p>
              成果報酬型と月額固定型の料金体系からお選びいただけます。
              初年度はコンサルタントが深く入り込んだご支援によるサービス提供を行い、翌年以降はクラウドサービスのみのご利用も可能です。
              詳細の料金設計に関しましては、お問い合わせください。
            </p>
          </details>
          <details className="qa">
            <summary>Q. 短期間の契約は可能ですか？</summary>
            <p>基本的には1年契約ですが、より短期間のご提案も可能です。</p>
          </details>
          <details className="qa">
            <summary>Q. どこまでリファラル採用の支援をしてくれるのですか？</summary>
            <p>
              社員様向けの説明会や資料準備、広報記事の作成、システムの運用など、あらゆる施策実施を対応しております。
            </p>
          </details>
          <details className="qa">
            <summary>Q. どれくらいの採用成果が見込めますか？</summary>
            <p> 初回商談時に、ヒアリングのちに成果シミュレーションをさせていただきます。</p>
          </details>
          <details className="qa">
            <summary>Q. 制作物のサポートはありますか？</summary>
            <p>はい、カードやポスターのグッズ作成や、オンラインにて配信可能な記事作成のサポートを行っております。</p>
          </details>
          <details className="qa">
            <summary>Q. クラウドサービス（Refcome）は使わずに、施策支援だけの依頼は可能ですか？</summary>
            <p>
              はい、可能です。
              クラウドサービスを使わない形で、社員の皆様が紹介しやすい動線設計を行うこともご支援しております。
            </p>
          </details>
          <details className="qa">
            <summary>Q. Refcomeのデモ環境は使えますか？</summary>
            <p>
              はい、可能です。
              デモ環境の発行前に、営業担当から、画面を映しながらどのようなことができるのかも、ご案内させていただいております。
            </p>
          </details>
        </div>
      </div>

      <style jsx>{`
        .container {
          display: flex;
          justify-content: center;
          gap: 32px;
          margin: 48px 0;
        }
        .titleWrapper {
          width: 158px;
        }
        .title {
          font-size: 26px;
          font-weight: bold;
        }
        .qaWrapper {
          width: 810px;
        }
        .qa {
          width: 100%;
          margin-bottom: 7px;
          border-bottom: 2px solid ${colors.gray3};
        }
        .qa summary {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          padding: 1em 1em;
          color: ${colors.defaultText};
          cursor: pointer;
          font-size: 18px;
        }
        .qa summary::-webkit-details-marker {
          display: none;
        }
        .qa summary::before,
        .qa summary::after {
          width: 2px;
          height: 0.9em;
          border-radius: 5px;
          background-color: ${colors.defaultText};
          content: "";
        }
        .qa summary::before {
          position: absolute;
          right: 1em;
          rotate: 90deg;
        }
        .qa summary::after {
          transition: rotate 0.3s;
        }
        .qa[open] summary {
          font-weight: bold;
        }
        .qa[open] summary::after {
          rotate: 90deg;
        }
        .qa p {
          transform: translateY(-10px);
          opacity: 0;
          margin: 0;
          padding: 0.1em 3.5em 1.5em;
          color: ${colors.defaultText};
          transition: transform 0.5s, opacity 0.5s;
          font-size: 16px;
        }
        .qa[open] p {
          transform: none;
          opacity: 1;
        }
        @media (${breakPoints.tablet}) {
          .container {
            flex-direction: column;
            gap: 10px;
            margin: 48px 20px;
          }
          .titleWrapper {
            width: 100%;
          }
          .qaWrapper {
            width: 100%;
          }
          .qa {
            width: 100%;
          }
        }
        @media (${breakPoints.sp}) {
          .container {
            flex-direction: column;
            gap: 10px;
            margin: 48px 20px;
          }
          .titleWrapper {
            width: 100%;
          }
          .qaWrapper {
            width: 100%;
          }
          .qa {
            width: 100%;
          }
          .qa summary {
            padding: 1em 0;
            font-size: 16px;
          }
          .qa summary::before {
            right: 0;
          }
          .qa p {
            padding: 0.1em 1em 1em;
          }
        }
      `}</style>
    </>
  );
};
