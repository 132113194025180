import React, { FC } from "react";
import { colors } from "~/utils/colors";
import { TelephoneNumberPanel } from "~/organisms/TelephoneNumberPanel";
import { DocumentDownloadButton } from "~/organisms/DocumentDownloadButton";
import { breakPoints } from "~/utils/variables";
import { PATH } from "~/constant/path";

type DocumentGuide = {};

export const DocumentDownloadGuid: FC<DocumentGuide> = () => {
  return (
    <>
      <div className="container">
        <span className="description">
          お問い合わせいただいた方には
          <br />
          料金プランや利用開始時期を営業担当から順次ご案内いたします。
        </span>
        <DocumentDownloadButton to={PATH.INQUIRY_CONTACT.to} />
        <TelephoneNumberPanel />
      </div>

      <style jsx>
        {`
          .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            background: ${colors.lightOrange};
            padding: 40px 20px;
            gap: 32px;
          }
          .description {
            color: ${colors.defaultText};
            text-align: center;
            font-size: 20px;
          }
          @media (${breakPoints.sp}) {
            .description {
              font-size: 16px;
            }
          }
        `}
      </style>
    </>
  );
};
